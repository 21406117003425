<template>
  <div>
    <div class="div-companies content-wrapper">

      <TitlePage :title="titlePage" :iconClass="iconClass" />

      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card title-height">
              <div class="card-header">
                <h3 class="card-title">
                  Total de empresas.
                  <b>{{companies.length}}</b>
                </h3>
                <div class="card-tools">
                  <DataTrial v-if="selectedCompany" :selectedCompany="selectedCompany"
                   @close-modal="closeCompanyModal" @update-list="getCompanies('trial')"/>
                  <div class="input-group input-group-sm" style="width: 400px;">
                    <div class="dropdown">
                    <button class="btn dropdown-toggle mr-1 border" type="button" data-bs-toggle="dropdown"> Empresas
                    <span class="caret"></span></button>
                    <ul class="dropdown-menu">
                      <li class="ml-2 mb-1 mr-2" style="color:#343a40;" @click="getCompanies('all')">Todas</li>
                      <hr>
                      <li class="ml-2 mb-1 mr-2" style="color:#1b4068;" @click="getCompanies('', 'TALLOS')" > TALLOS </li> 
                      <li class="ml-2 mb-1 mr-2" style="color:#1b4068;" @click="getCompanies('', 'associated')"> Associados </li> 
                    </ul>

                  </div>
                    <div class="dropdown">
                      <button class="btn dropdown-toggle mr-1 border" type="button" data-bs-toggle="dropdown">Filtrar
                      <span class="caret"></span></button>
                      <ul class="dropdown-menu">
                        <li class="ml-2 mb-1 mr-2" style="color:#ED4C67;" @click="getCompanies('trial')">Trial</li> 
                        <li class="ml-2 mb-1 mr-2" style="color:#17a2b8;" @click="getCompanies('basic')">Basico</li> 
                        <li class="ml-2 mb-1 mr-2" style="color:#28a745;" @click="getCompanies('essential')">Essencial</li> 
                        <li class="ml-2 mb-1 mr-2" style="color:#007bff;" @click="getCompanies('advanced')">Avançado</li> 
                        <li class="ml-2 mb-1 mr-2" style="color:#343a40;" @click="getCompanies('professional')">Profissional</li> 
                        <hr>
                        <li class="ml-2 mb-1 mr-2" style="color:#343a40;" @click="getCompanies('all')">Todas</li>
                      </ul>
                    </div>
                    <button
                      class="btn btn-default btn-sm mr-1"
                      title="atualizar"
                      @click="getCompanies()"
                    >
                      <i class="fas fa-sync"></i>
                    </button>
                    <input
                      @keyup="getFilterCompanies()"
                      v-model="searchCompanies"
                      title="Pesquisar Empresa"
                      type="text"
                      name="table_search"
                      class="form-control float-right input-search"
                      :placeholder="`Pesquisar Empresa por nome, ID ou CNPJ`"
                    />
                    <i
                      class="fas fa-search lupa-style"
                      @click="getFilterCompanies()"
                      title="Perquisar empresa"
                    ></i>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table class="table table-hover table-stripped table-head-fixed">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nome</th>
                      <th>Canal</th>
                      <th>Último acesso</th>
                      <th class="text-center">Planos</th>
                      <th>Status</th>
                      <th>Nps</th>
                      <th>N.Star</th>
                      <th>Status</th>
                      <th class="justify-content-center" style="height: 47px;">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(company, i) in companies" :key="i" >
                      <td v-if="showButtonReview">
                        <button class="btn btn-xs"
                        :class="companyChecked(company) ? 'btn-outline-primary' : 'btn-default'"
                        @click="companyChecked(company) ? markedOrNotMarkedCompany(company, false) : markedOrNotMarkedCompany(company, true)"
                        >
                        <i class="fa fa-star"></i>
                        </button>
                      </td>
                      <td>{{company._id}}</td>
                      <td>
                        <div class="d-flex justify-content-start">
                          <span v-if="company.isEngaged" class="mr-2 span-engaged-disengaged d-flex justify-content-center" 
                            :class="company.isEngaged ? 'engaged' : 'disengaged'" :title="company.isEngaged ? 'Empresa engajada' : 'Empresa desengajada'">
                            <i class="fa" :class="company.isEngaged ? 'fa-thumbs-up' : 'fa-thumbs-down'"></i>
                          </span>
                          <span v-else class="mr-2 span-engaged-disengaged disengaged d-flex justify-content-center">
                            <i class="fa fa-thumbs-down"></i>
                          </span>
                          {{company.name}}
                        </div>
                      </td>
                      <td class="text-center m-2">{{ filterSaleChannel(company.sales_channel) }}</td>
                      <td class="td-last-accsess">
                        {{company.last_access_in_platform ? formatData(company.last_access_in_platform, 'llll') : '--------------------------'}}
                      </td>
                      <td class="text-center">
                        <div class="d-flex justify-content-center flex-column align-items-center">
                          <span :class="plans[company.account_type].class">{{plans[company.account_type].name}}</span>
                          <span v-if="company.account_is_trial == true" class="badge mt-2 bg-danger">Trial</span>
                        </div>
                      </td>
                      <td>
                        <div v-if="!company.status_payment_on_superlogica" class="spinner-border spinner-size" role="status"/>
                        <span v-if="company.status_payment_on_superlogica == 1" class="badge bg-danger">Inadimplente</span>
                        <span v-if="company.status_payment_on_superlogica == 2" class="badge bg-success">Adimplente</span>
                        <span v-if="company.status_payment_on_superlogica == 3" class="badge bg-secondary">Não resgistrado</span>
                      </td>    
                      <td class="text-center">
                        {{
                          company.last_average_nps === 0 || company.last_average_nps ? company.last_average_nps : 'S/A' 
                        }}
                      </td>                                   
                      <td class="text-center" style="font-weight:600">
                        {{ !company.account_is_trial && ![null, undefined].includes(company.north_star) ? formatNorthStar(company.north_star.northStar) : '-' }}
                      </td>
                      <td class="text-center">
                         <router-link class="button-northstar" v-if="!company.account_is_trial && (company.account_disabled === false || company.status_payment_on_superlogica !== 3 )" tag="button" :to="{ name: 'northstar', params: { id: company._id } }">
                          <font-awesome-icon :icon="['fas', 'ranking-star']" />
                         </router-link>
                         <div v-else>
                          <font-awesome-icon class="text-danger" data-toggle="tooltip" data-placement="left" title="Clientes trial, não ativos ou com plano diferente de essencial, avançado ou profissional não estão aptos ao north star" icon="fa-solid fa-circle-info" />
                         </div>
                      </td>
                      <td class="d-flex justify-content-center">
                        <router-link
                          tag="button"
                          :to="{ name: 'edit_company', params: { id: company._id, rd_billing: false }}"
                          class="btn btn-outline-secondary btn-sm"
                          title="Editar Empresa"
                        >
                          <i class="fa fa-edit"></i>
                        </router-link>

                        <router-link
                          tag="button"
                          :to="{ name: 'company_employees', params: {company_id: company._id, company_name: company.name, rd_billing: false}}"
                          class="btn btn-outline-primary btn-sm ml-1"
                          title="Empregados da empresa"
                        >
                          <i class="fa fa-users"></i>
                        </router-link>

                        <router-link
                          tag="button"
                          :to="{ name: 'tickets_company', params: {id: company._id, }}"
                          class="btn btn-outline-primary btn-sm ml-1"
                          title="Tickets"
                        >
                          <i class="fas fa-ticket-alt" aria-hidden="true"></i>
                        </router-link>

                        <router-link
                          tag="button"
                          :to="{ name: 'info_company', params: {id: company._id, rd_billing: false }}"
                          class="btn btn-outline-primary btn-sm ml-1"
                          title="Informações"
                        >
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </router-link>
                        <button
                          v-if="trialCompany(company)"
                          tag="button"
                          class="btn btn-outline-danger btn-sm ml-1"
                          title="Deletar Empresa"
                          @click="openCompanyModal(company)"
                        >
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="getCompaniesStatus" class="d-flex justify-content-center align-items-center mt-2 mb-2">
                  <div class="spinner-border spinner-size" role="status"/>
                  <span class="ml-2">Carregando Empresas...</span>
                </div>
              </div>
              <section class="buttons d-flex justify-content-center mb-3">
                <nav class="mt-3 d-flex">
                  <button :class="'page-link'"
                    @click="firstPage()">
                    Primeira 
                  </button>
                  <button :class="'page-link ml-2 mr-2'"
                    @click="previousPage()">
                      Anterior 
                  </button>
                  <button :class="'page-link'"
                    @click="nextPage()">
                      Próxima 
                  </button>
                </nav>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment-timezone'
import "moment/locale/pt-br.js"
import TitlePage from '../../shared/components/title-page/TitlePage.vue'
import northStartCompanies from '../../../services/northStar';
import DataTrial from '../../views/companies/datatrials/index.vue'
export default {
  components: {
    TitlePage,
    DataTrial
  },
  data() {
    return {
      selectedCompany: null,
      showDataTrial: false,
      nortStarService: new northStartCompanies(),
      titlePage: 'Empresas',
      iconClass: 'fa fa fa-building',
      company: {},
      companies: [],
      getCompaniesStatus: false,
      perPage: 12,
      currentPage: 1,
      searchCompanies: "",
      companiesGroup: [],
      showButtonReview: false,
      checkAllCompanies: false,
      companiesChecks: [],
      newModalGeneralSelect: 1,
      currentPlanSelected: "",
      plans: {
        undefined: {
          name: "",
          class: "",
        },
        enterprise: {
          name: "Enterprise",
          class: "badge bg-primary"
        },
        voip: {
          name: "VoIP",
          class: 'badge bg-voip'
        },
        basic: {
          name: "Basico",
          class: 'badge bg-info'
        },
        essential: {
          name: "Essencial",
          class: 'badge bg-success'
        },
        advanced: {
          name: "Avançado",
          class: 'badge bg-primary'
        },
        professional: {
          name: "Profissional",
          class: 'badge bg-dark'
        },
        premium: {
          name: "Premium",
          class: 'badge bg-warning'
        },
        trial: {
          name: "Trial",
          class: 'badge bg-danger'
        },
        interno: {
          name: "Interno",
          class: 'badge bg-secondary'
        }
      }
    };
  },
  computed: {
  trialCompany() {
    return (company) => company.account_is_trial && this.$store.state.admin.perfil === 'admin' && company.broker_finance === 'superlogica'
  },
},
  watch: {
    newModalGeneralSelect() {
      if (this.newModalGeneralSelect == 2) {
        this.companiesChecks = this.companies.map(c => c._id);
      } else if (this.newModalGeneralSelect == 3) {
        this.companiesChecks = [];
      }
      this.newModalGeneralSelect = 1;
    },
    'currentPage'() {
      if(this.currentPlanSelected) {
        this.getCompanies(this.currentPlanSelected, '', true);
      }else {
      this.getCompanies(this.currentPlanSelected, 'all', true);

      }
    }
  },
  methods: {
    openCompanyModal(company) {
      this.selectedCompany = company;
    },
    closeCompanyModal() {
      this.selectedCompany = null;
    },
    formatNorthStar(northStar ) {
      if(northStar !== null && northStar !== undefined ){
        return northStar ? 'Sim' : 'Não';
      }
      return '-';
    },
    filterSaleChannel(salesChannel) {
    const channelMap = {
      'associated': 'ASSOCIADO',
    };
    return channelMap[salesChannel] || salesChannel;
  },
     async getCompanies(plan, param, pagination = false) {
      this.companies = [];
      this.companiesGroup = [];
      this.getCompaniesStatus = true;
      this.currentPlanSelected = plan;

      if (!pagination) {
        this.perPage = 12;
        this.currentPage = 1;
      }

      const params = new URLSearchParams();

      if (param === "TALLOS" || param === "associated") {
        params.append("perPage", this.perPage);
        params.append("page", this.currentPage);
        params.append("sales_channel", param);
      } else if (!plan || plan === "all") {
        params.append("perPage", this.perPage);
        params.append("page", this.currentPage);
      } else {
        params.append("account_type", plan);
        params.append("perPage", this.perPage);
        params.append("page", this.currentPage);
      }

      const objectIdRegex = /^[0-9a-fA-F]{24}$/;
      const cnpjRegex = /\d{2}\.?\d{3}\.?\d{3}\/?\d{4}\-?\d{2}/g;

      if (this.searchCompanies && objectIdRegex.test(this.searchCompanies)) {
        params.append("id", this.searchCompanies);
      } else if (this.searchCompanies && cnpjRegex.test(this.searchCompanies)) {
        params.append("cnpj", this.searchCompanies);
      } else if (this.searchCompanies) {
        params.append("search", this.searchCompanies);
      }

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.tokenJWT}`,
        },
      };

      try {
        const res = await this.$tallos_admin_api.get("companies", {
          params,
          headers: config.headers,
        });
        this.companies = res.data;
        this.companiesGroup = res.data;
        this.getCompaniesStatus = false;
        this.$toasted.global.defaultSuccess({
          msg: "Empresas encontradas.",
        });
      } catch (e) {
        this.$toasted.global.defaultError({
          msg: "Erro ao buscar empresas.",
        });
      }
    },
   async getCompanyNorthStatus(id) {
      let response = await this.nortStarService.getCompanyStatus(id)
      this.company = response
      return response
    },
    getCompaniesForPlan(plan, increment = false) {
      this.companies = []; 
      this.companiesGroup = [];
      this.getCompaniesStatus = true;
      this.currentPlanSelected = plan;
      if(!increment){
        this.perPage = 50;
        this.currentPage = 1;
      }

      let url = `companies/?account_type=${plan}&perPage=${this.perPage}&page=${this.currentPage}`
      const objectIdRegex = /^[0-9a-fA-F]{24}$/;

      if (this.searchCompanies && objectIdRegex.test(this.searchCompanies)) {
        url += `&id=${this.searchCompanies}`;
      } else if (this.searchCompanies) {
        url += `&search=${this.searchCompanies}`;
      }

      this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;

      this.$tallos_admin_api.get(url)
        .then(res => {
          this.companies = res.data;
          this.companiesGroup = res.data;
          this.getCompaniesStatus = false;
          this.$toasted.global.defaultSuccess({
            msg: "Empresas encontradas."
          });
            })
            .catch(e => {
          this.$toasted.global.defaultError({
            msg: "Erro ao buscar empresas."
          });
        });
    },
    
    calcTotalPages() {
      return !(this.currentPage < this.perPage)
    },

    firstPage() {
      this.currentPage = 1
      this.perPage = 12
      this.getCompanies(this.currentPlanSelected, 'all', true);

    },

    lastPage() {
      this.currentPage = this.perPage
    },

    nextPage() {
      if (this.companies.length >= this.perPage) {
        this.currentPage++
        this.perPage = 12; // Aumentar a quantidade de empresas exibidas por página
        this.getCompanies(this.currentPlanSelected, 'all', true);
        }
    },

    previousPage() {
      if (this.currentPage > 1) {
         this.currentPage--
        this.getCompanies(this.currentPlanSelected, 'all', true);
        return this.currentPage
      } else {
        return this.currentPage = 1
      }
    },

    getFilterCompanies() {
      clearTimeout(this.getFilterCompanies.timeout);

      this.getFilterCompanies.timeout = setTimeout(() =>{
        if(this.currentPlanSelected) {
        this.getCompaniesForPlan(this.currentPlanSelected);
        } else {
          this.getCompanies()
        }
      }, 500)
    },

    companyChecked(company) {
      return this.companiesChecks.indexOf(company._id) !== -1;
    },

    markedOrNotMarkedCompany(company, marked) {
      if (marked) {
        this.companiesChecks.push(company._id);
      } else {
        let indexOfCompany = this.companiesChecks.indexOf(company._id);
        this.companiesChecks.splice(indexOfCompany, 1);
      }
    },

    filterCompaniesCheckNoCheck() {
      return {
        companiesChecked: this.companies
          .filter(company => {
            let companies = [];
            for (let i = 0; i < this.companiesChecks.length; i++) {
              if (company._id == this.companiesChecks[i]) {
                return company;
              }
            }
          })
          .map(company => company._id),

        companiesNoChecked: this.companies
          .filter(company => {
            if (this.companiesChecks.indexOf(company._id) == -1) {
              return company;
            }
          })
          .map(company => company._id)
      };
    },

    updateApresentation() {
      this.$tallos_admin_api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.$store.getters.tokenJWT}`;

      this.$tallos_admin_api
        .put("companies/update-apresentation-companies", {
          companiesChecked: this.filterCompaniesCheckNoCheck().companiesChecked,
          companiesNoChecked: this.filterCompaniesCheckNoCheck()
            .companiesNoChecked
        })
        .then(res => {
          this.getFilterCompanies();
          this.showButtonReview = false;
          this.$toasted.global.defaultSuccess({ msg: "Empresas atualizadas." });
        })
        .catch(e => {
          this.$toasted.global.defaultError({
            msg: "Erro ao atualizar Empresas."
          });
        });
	},
	
	formatData(data, format) {
		return moment(data).format(format)
	},

  },

  created() {
    this.getCompanies();
    document.title = "Tallos Admin - Empresas";
  },
};
</script>

<style scoped>
.div-companies {
  background-color: #fff;
}

.badge {
  width: 100px;
}

.bg-voip {
  background: #9c27b0;
  color: #fff;
}

.lupa-style {
  position: absolute;
  right: 10px;
  top: 8px;
  color: #0008;
  cursor: pointer;
}

.input-search {
  border-radius: 3px !important;
}

.select-review-companies {
  width: 15px !important;
  color: #0009;
  background: #fff;
  border: none;
  outline: none !important;
}

.td-last-accsess {
  width: 10rem;
  font-weight: 500;
	text-align: start;
}

.span-engaged-disengaged {
  display: flex;
  justify-content: center;
  align-items: center;
}

.engaged {
	font-weight: 600;
	font-size: 14px;
	color: rgb(40,167,69);
}

.disengaged {
	font-weight: 600;
	font-size: 14px;
	color: rgb(245, 81, 31);
}

.dropdown-menu li {
  outline: none;
  border: none;
  background: white;
  color: #0652DD;
  font-weight: 600;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #ddd;
}

.title-height {
  height: 600px;
}

.spinner-size {
   max-height: 20px; 
   max-width: 20px;
}
.button-northstar{
border:none;
color: #ffa500;
}
</style>
