import axios from "axios";
export default class northStartCompanies {
  megasac_api = axios.create({
    baseURL: process.env.VUE_APP_URL_TALLOS_ADMIN_API,
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${this.$store.getters.tokenJWT}`,
    },
  });

  async getCompaniesCustomerX(query) {
    try{
      let result = await this.megasac_api.get(
        `/northstar/companies?perPage=${query.perPage}&page=${query.page}&name=${query.name}&cnpj=${query.cnpj}`
      );
      return result.data;

    }catch(error){
      throw new Error('Erro ao gerar experiência do cliente.')

    }
  }
  
  async getCompanyStatus(data){
    try{
      let company = await this.megasac_api.get(`/northstar/companies/status/${data}`)
      return company;
    }catch(error){
      throw new Error('Erro ao listar status desta empresa.')

    }
  }
  async getNorthStarsByPeriod(query){
    try{
      let { company_id, formattedStartDate, formattedEndDate } = query;
      let company = await this.megasac_api.get(`/northstar/company/status/period?company_id=${company_id}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`)
      return company.data[0];
    }catch(error){
      throw new Error('Erro ao listar dados por período.')
    }
  }
}
