<template>
  <div v-if="selectedCompany" class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" @click="closeModal">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p class="title-company">
            Empresa: {{ selectedCompany.razao_social }}
          </p>
          <p class="title-integration">
            Integração:
            {{ selectedCompany.whatsapps_integrations_type.toUpperCase() }}
          </p>
          <p class="title-integration">
            Criação: {{ formatDate(selectedCompany.created_at) }}
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="startLoading">
            Excluir
          </button>
          <button type="button" class="btn btn-danger" @click="closeModal">
            Cancelar
          </button>
        </div>
      </div>
      <div v-if="isLoading" class="loading">Empresa trial excluída</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["selectedCompany"],
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    updateList() {
      this.$emit("update-list");
    },
    closeModal() {
      this.$emit("close-modal");
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    startLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        this.deleteTrial(this.selectedCompany._id);
        this.closeModal();
      }, 3000);
    },
    async deleteTrial(id) {
      this.$tallos_admin_api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.$store.getters.tokenJWT}`;
      try {
        await this.$tallos_admin_api.delete(`trial/company/${id}`);
        this.$toasted.global.defaultSuccess({
          msg: "Empresa excluída.",
        });
        this.updateList();
      } catch (e) {
        this.$toasted.global.defaultError({ msg: "Empresa não encontrada." });
      }
    },
  },
};
</script>

<style scoped>
.title-integration {
  font-size: 1.5rem;
}

.title-company {
  font-size: 1.5rem;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  background-color: white;
  max-width: 400px;
  width: 80%;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.modal-header {
  background-color: #f7f7f7fb;
  color: white;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.modal-title {
  margin: 0;
  font-size: 18px;
}

.modal-body {
  padding: 20px;
}

.modal-footer {
  background-color: #f5f5f5;
  text-align: right;
  padding: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 24px;
}
</style>
